.wrapper {
  padding-top: 30px;
}

/* Navbar */

.navbar-brand a {
  color: #ffffff;
}

.navbar-brand a:hover {
  color: #ffffff;
  text-decoration: underline;
}

/* Form wrapper & table wrapper */

.FormWrapper,
.form-wrapper,
.table-wrapper {
  margin: 0 auto;
  clear: both;
}

/* Form wrapper */

.form-wrapper h4 {
  margin-bottom: 30px;
}

/* Form group */

.form-group {
  margin-bottom: 1rem;
}

/* Breadcrumbs */

.breadcrumbs {
  float: right;
}

/* Table wrapper */

.table-wrapper .header-link {
  float: right;
  text-align: center;
  margin: 0 0 10px 0;
  padding: 10px 10px;
  background-color: #28a745;
  border-color: #28a745;
  color: #ffffff;
}

.table-wrapper .header-link:hover {
  color: #ffffff;
  text-decoration: underline;
}

/* Data display tables */

.action-button-padded {
  margin-right: 10px;
}

.grid-actions-header-small {
  width: 170px;
}

.grid-actions-header-large {
  width: 300px;
}

.grid-actions-header-medium {
  width: 220px;
}

.update-link {
  padding: 6px 10px;
  font-size: 0.875rem;
  line-height: normal;
  border-radius: 0.2rem;
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  margin-right: 10px;
  position: relative;
  top: 1px;
  text-decoration: none;
}

.update-link:hover {
  text-decoration: underline;
  color: #ffffff;
}

/* Holder for form submission buttons */

.form-submission {
  margin-bottom: 50px;
}

/* Create button used in forms */

.create-btn {
  background-color: #28a745 !important;
  border-color: #28a745 !important;
  margin-right: 10px;
}

/* Confirmation dialog */

#react-confirm-alert {
  z-index: 1060;
  position: relative;
}

.confirmation-dialog {
  text-align: center;
  width: 500px;
  padding: 40px;
  background: #28bae6;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #fff;
}

.confirmation-dialog p {
  font-size: 1.4rem;
}

.confirmation-dialog button {
  width: 160px;
  padding: 10px;
  border: 1px solid #fff;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: #fff;
  font-size: 14px;
}

.confirmation-dialog button.confirm {
  background-color: #28a745;
}

.confirmation-dialog button.reject {
  background-color: #dc3545;
}

/* Container for URL content */

.url-container {
  overflow-wrap: break-word;
}

/* Data table pagination */

.pagination {
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

/* Wrapper for a set of options in the admin tools page */

.admin-tool {
  margin-top: 30px;
}

/* Engagement history search field wrapper */

.history-search {
  margin-top: 40px;
}

.bounce-sub-heading {
  margin-top: 40px;
}

/* Engagement history */

.modal-options {
  float: right;
}

/* Deactivated rulesets */

.deactivated-rulesets {
  margin: 40px 0 90px 0;
}

/* Toggle switch */

.toggle-switch {
  position: relative;
  width: 75px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
  margin-bottom: 20px;
}

.toggle-switch-checkbox {
  display: none;
}

.toggle-switch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 20px;
  margin: 0;
}

.toggle-switch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}

.toggle-switch-inner:before,
.toggle-switch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 34px;
  padding: 0;
  line-height: 34px;
  font-size: 14px;
  color: white;
  font-weight: bold;
  box-sizing: border-box;
}

.toggle-switch-inner:before {
  content: "Yes";
  text-transform: uppercase;
  padding-left: 10px;
  background-color: #018749;
  color: #fff;
}

.toggle-switch-disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.toggle-switch-disabled:before {
  background-color: #ddd;
  cursor: not-allowed;
}

.toggle-switch-inner:after {
  content: "No";
  text-transform: uppercase;
  padding-right: 10px;
  background-color: #FF0000;
  color: #fff;
  text-align: right;
}

.toggle-switch-switch {
  display: block;
  width: 24px;
  margin: 5px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 40px;
  border: 0 solid #bbb;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}

.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
  margin-left: 0;
}

.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
  right: 0px;
}
